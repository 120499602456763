<template>
  <q-dialog v-model="isOpen" class="modal-xl">
    <q-card>
      <q-card-section class="row">
        <div class="text-h6 text-center">
          {{ $t('Info') }}
        </div>

        <q-space />

        <q-btn
          color="transparent"
          text-color="dark"
          size="sm"
          icon="close"
          no-caps
          unelevated
          @click="hide"
        />
      </q-card-section>

      <q-card-section>
        <div class="q-mb-sm">
          <q-select
              standout="bg-teal text-white"
              :model-value="locale.name"
              :options="locales"
              :label="$t('Language')"
          >
            <template
                v-slot:option="props"
            >
              <div
                  class="q-pa-sm card--clickable card--clickable-no-scale"
                  :class="props.selected ? 'bg-success' : ''"
                  style="min-width: 150px;"
                  @click="handleLocale(props.opt)"
              >
                {{ props.opt.name }}
              </div>
            </template>
          </q-select>
        </div>

        <q-select
            v-if="themes.length > 1"
            standout="bg-teal text-white"
            :model-value="theme"
            :options="themes"
            :label="$t('Theme')"
        >
          <template
              v-slot:option="props"
          >
            <div
                class="q-pa-sm card--clickable card--clickable-no-scale"
                :class="props.selected ? 'bg-success' : ''"
                style="min-width: 150px;"
                @click="handleTheme(props.opt)"
            >
              {{ props.opt.name }}
            </div>
          </template>
        </q-select>

        <q-toggle
            :model-value="$q.dark.isActive"
            :label="$t('Dark Mode')"
            @update:model-value="handleDarkMode"
        />

        <q-toggle
            :model-value="appOptions.debug"
            :label="$t('Developer mode')"
            @update:model-value="handleDebug"
        />
      </q-card-section>

      <q-card-section>
        <shops-table :hide-actions="isClient" />
      </q-card-section>

      <q-card-section>
        <warehouses-table :hide-actions="isClient" />
      </q-card-section>

      <q-card-section>
        <senders-table :hide-actions="isClient" />
      </q-card-section>

      <q-card-section v-if="appOptions.versionType !== 'simple'">
        <accounts-table :hide-actions="isClient" />
      </q-card-section>

      <q-card-section v-if="appOptions.versionType !== 'simple'">
        <legal-entities-table :hide-actions="isClient" />
      </q-card-section>

      <q-card-section v-if="appOptions.versionType === 'simple'">
        <sources-table :hide-actions="isClient" />
      </q-card-section>

    </q-card>
  </q-dialog>
</template>

<script>
// Components
import SendersTable from '../tables/SendersTable.vue'
import ShopsTable from '../tables/ShopsTable.vue'
import WarehousesTable from '../tables/WarehousesTable.vue'
import AccountsTable from '../tables/AccountsTable.vue'
import LegalEntitiesTable from '../tables/LegalEntitiesTable.vue'
import SourcesTable from '../tables/SourcesTable.vue'

// Vuex
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'InfoModal',
  components: {
    ShopsTable,
    WarehousesTable,
    SendersTable,
    AccountsTable,
    LegalEntitiesTable,
    SourcesTable
  },
  data () {
    return {
      isOpen: false,
      locale: window.appOptions.locale,
      locales: window.appOptions.locales,
      theme: window.appOptions.theme,
      themes: window.appOptions.themes,
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'appOptions',
    ])
  },
  methods: {
    ...mapMutations([
      'updateAppOptions'
    ]),
    open () {
      this.isOpen = true
    },
    hide () {
      this.isOpenFilter = false
      this.isOpen = false
    },
    handleLocale (locale) {
      this.locale = locale
      window.appOptions.locale = locale

      this.$q.cookies.set('locale', JSON.stringify(locale))
      localStorage.setItem('appOptions', JSON.stringify(window.appOptions))

      this.$i18n.locale = window.appOptions.locale.locale
    },
    handleTheme ({ theme }) {
      this.updateAppOptions({ theme })
      this.theme = theme
    },
    handleDarkMode () {
      this.$q.dark.set(!this.$q.dark.isActive)
      localStorage.setItem('darkMode', JSON.stringify(this.$q.dark.isActive))
    },
    handleDebug (debug) {
      this.updateAppOptions({ debug })
    },
  }
}
</script>
