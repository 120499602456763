<template>
  <div
    class="row items-center"
  >
    <q-btn
      color="amber-9"
      text-color="white"
      no-caps
      style="font-weight: bold"
      :label="$t('New orders') + ': ' + this.orders || 0"
      @click="handleClick('/orders')"
    />
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'OrderNotification',
  data () {
    return {
      prefix: '',
      ordersLoading: true,
      orders: null
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ])
  },
  mounted () {
    if (this.appOptions.versionType !== 'simple') {
      this.prefix = '/outbound'
    }

    this.loadItems()
  },
  methods: {
    ...mapActions([
      'loadOrders'
    ]),
    handleClick (route) {
      this.$router.push(this.prefix + route)
    },
    loadItems () {
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'in', field: 'state', values: ['pending', 'confirmed', 'reserved'] }
        ]
      }

      return this.loadOrders(query)
        .then(({ totalItems }) => {
          this.ordersLoading = false
          this.orders = totalItems
        })
    }
  }
}
</script>
