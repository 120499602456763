<template>
  <q-btn-dropdown
      unelevated
      no-caps
      dense
      flat
      :style="{ padding: '4px 13px', display: 'flex', alignItems: 'center' }"
  >
    <template v-slot:label>
      <q-icon name="account_circle" style="margin-right: 8px;" />
      <span>{{ this.user.username }}</span>
    </template>

    <div class="relative row no-wrap q-pa-md" style="display: block;margin: 0 auto;">

      <div class="column" v-if="appOptions.versionType !== 'simple'">
        <div class="text-center">
          <q-btn
              v-close-popup
              color="primary"
              :label="$t('Entity Settings')"
              class="full-width q-mt-xs"
              push
              size="sm"
              @click="openInfo"
          />
        </div>

        <div class="text-center">
          <q-btn
              v-close-popup
              push
              icon="print"
              class="full-width q-mt-sm"
              size="sm"
              :label="isConnectedPrinterBg ? 'online' : 'offline'"
              :color="connectedPrinterBg"
              @click="openPrinterSettings"
          />
        </div>
      </div>

      <q-separator
          v-if="appOptions.versionType !== 'simple'"
          vertical
          inset
          class="q-mx-lg"
      />

      <div class="column items-center">
        <q-btn
            v-close-popup
            color="light-blue-9"
            :label="appOptions.versionType === 'simple' ? $t('My Profile') : $t('My Account')"
            push
            size="sm"
            class="full-width"
            @click="handleOpenProfile"
        />

        <q-btn
            v-if="!isClient && appOptions.versionType !== 'simple'"
            v-close-popup
            color="warning"
            :label="$t('open pro interface')"
            class="full-width q-mt-sm"
            push
            size="sm"
            @click="goToProSite"
        />

        <q-btn
            v-if="appOptions.versionType !== 'simple'"
            v-close-popup
            color="negative"
            :label="$t('Logout')"
            push
            size="sm"
            class="full-width q-mt-sm"
            @click="handleLogout"
        />

        <q-btn
            v-if="appOptions.versionType === 'simple'"
            v-close-popup
            color="secondary"
            :label="$t('Settings')"
            class="full-width q-mt-sm"
            push
            size="sm"
            @click="openInfo"
        />
      </div>
    </div>
  </q-btn-dropdown>

  <info-modal ref="infoModal"/>

  <printer-settings-modal ref="printerSettingsModal"/>

  <user ref="userModal"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import User from '../user/User'
import PrinterSettingsModal from '../modals/PrinterSettingsModal.vue'
import InfoModal from '../modals/InfoModal.vue'
import gravatar from 'gravatar'

// Services
import { UserService } from '../../services'

export default {
  name: 'UserDropdown',
  components: {
    User,
    PrinterSettingsModal,
    InfoModal
  },
  data () {
    return {
      isConnectedPrinterBg: false,
      locale: window.appOptions.locale,
      locales: window.appOptions.locales,
      theme: window.appOptions.theme,
      themes: window.appOptions.themes,
      printerSub: null
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'appOptions',
      'currentUser',
      'isClient'
    ]),
    connectedPrinterBg () {
      return this.isConnectedPrinterBg || (this.printer && this.printer.connected)
          ? 'green'
          : 'danger'
    },
    user () {
      return this.currentUser
    },
    username () {
      const text = this.user.username || this.user.email || ''
      const currentUsername = text.slice(0, 20)

      return currentUsername.length === text.length
          ? text
          : text + '...'
    }
  },
  mounted () {
    this.printerSub = this.$service.printer._printer.subscribe('stateChange', (isConnected) => {
      this.isConnectedPrinterBg = isConnected
    })
  },
  unmounted () {
    this.printerSub.unsubscribe()
  },
  methods: {
    ...mapMutations([
      'updateAppOptions'
    ]),
    openInfo () {
      this.$refs.infoModal.open()
    },
    openPrinterSettings () {
      this.$refs.printerSettingsModal.open()
    },
    handleDarkMode () {
      this.$q.dark.set(!this.$q.dark.isActive)
      localStorage.setItem('darkMode', JSON.stringify(this.$q.dark.isActive))
    },
    handleDebug (debug) {
      this.updateAppOptions({ debug })
    },
    handleLocale (locale) {
      this.locale = locale
      window.appOptions.locale = locale

      this.$q.cookies.set('locale', JSON.stringify(locale))
      localStorage.setItem('appOptions', JSON.stringify(window.appOptions))

      this.$i18n.locale = window.appOptions.locale.locale
    },
    handleTheme ({ theme }) {
      this.updateAppOptions({ theme })
      this.theme = theme
    },
    goToProSite () {
      const user = JSON.parse(localStorage.getItem('user'))
      const win = window.open(`${window.appOptions.defaultServer}/users/login/terminal?token=${user.refresh_token}`, '_blank')
      win.focus()
    },
    handleOpenProfile () {
      this.$refs.userModal.openCloseProfile()
    },
    handleLogout () {
      this.$channels.user.unsubscribe()
      this.$centrifuge.disconnect()

      UserService.logout()
      this.$eventBus.update('rules', { user: null, app: 'security' })
    }
  }
}
</script>
