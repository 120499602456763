<template>
  <q-card>
    <q-card-section class="row border-bottom items-center full-width q-py-xs q-pl-none">
      <q-legend :label="$t('Senders')" />

      <search
        dense
        autoset
        is-expandable
        @submit="handleSearch"
      />

      <q-space />

      <q-btn
        v-if="!hideActions" 
        :color="filters.length > 0 ? 'light-blue-9' : 'dark'"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="filterBtnText"
        no-caps
        unelevated
        @click="toggleFilter"
      />

      <q-btn
        v-if="!hideActions" 
        color="dark"
        text-color="white"
        :label="$t('Refresh')"
        size="sm"
        class="q-mr-sm"
        no-caps
        unelevated
        @click="refreshItems"
      />

      <q-btn
        v-if="!hideActions || options.addButton" 
        color="light-blue-9"
        text-color="white"
        icon="add"
        size="sm"
        no-caps
        unelevated
        @click="create"
      />
    </q-card-section>

    <filter-collapse
      :is-open="isOpen"
      :options="{
        defaultFilter: filters,
        fields: activatedFields,
        values: {
          states: statuses
        },
        style: {
          noGroups: true
        }
      }"
      @submit="handleFiltersSubmit"
      @close="toggleFilter"
    />

    <q-card-section class="q-ma-none q-pa-none">
      <q-table
        row-key="id"
        :rows-per-page-label="$t('Rows per page')"
        :rows="items"
        :columns=this.getColumns()
        v-model:pagination="pagination"
        :loading="isLoading"
        :filter="filter"
        :table-header-class="$q.dark.isActive ? '' : (options.style && options.style.header) || ''"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
      >
        <template v-slot:loading>
          <q-inner-loading
            showing
            color="primary"
          />
        </template>

        <template v-slot:body="props">
          <q-tr
            :props="props"
            class="clickable"
            @dblclick="onRowDblClick(props.row)"
          >
            <q-td
              key="id"
              :props="props"
              class="text-subtitle1"
            >
              <strong>{{ props.row.id }}</strong>
            </q-td>

            <q-td
              key="name"
              :props="props"
            >
              {{ props.row.name }}
            </q-td>

            <q-td
              key="parent"
              :props="props"
              v-if="appOptions.versionType !== 'simple'"
            >
              <span v-if="props.row._embedded.parent">
                {{ props.row._embedded.parent.name }}
              </span>
            </q-td>

            <q-td
              key="owner"
              :props="props"
            >
              <span v-if="props.row._embedded.owner">
                {{ props.row._embedded.owner.name }}
              </span>
            </q-td>

            <q-td
              key="created"
              :props="props"
            >
              {{ $moment(props.row.created.date).format(appOptions.formats.date) }}
            </q-td>

            <q-td
              key="state"
              :props="props"
            >
              <q-badge
                v-if="isClient"
                :color="stateColors[props.row.state]"
                :label="$t(props.row.state)"
              />

              <q-toggle
                v-else
                color="light-blue-9"
                :model-value="props.row.state === 'active'"
                @update:model-value="handleStateChange(props.row)"
              />
            </q-td>
          </q-tr>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import FilterCollapse from '../../components/filters/FilterCollapse.vue'
import Search from '../../components/search/Search.vue'

// Utils
import { buildQuery } from '../../utils/query-utils'

export default {
  name: 'SendersTable',
  components: {
    Search,
    FilterCollapse
  },
  props: {
    options: {
      type: Object,
      default () {
        return {
          filters: []
        }
      }
    },
    hideActions: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      isOpen: false,
      items: [],
      isLoading: false,
      stateColors: {
        active: 'teal',
        normal: 'success',
        blocked: 'dark'
      },
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 5,
        rowsNumber: 5
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left',
          simple: true
        },
        {
          label: this.$t('Name'),
          name: 'name',
          align: 'left',
          simple: true
        },
        {
          label: this.$t('Parent'),
          name: 'parent',
          align: 'left',
          simple: false
        },
        {
          label: this.$t('Owner'),
          name: 'owner',
          align: 'left',
          simple: true
        },
        {
          label: this.$t('Created'),
          name: 'created',
          align: 'left',
          simple: true
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          simple: true
        }
      ],
      statuses: [
        { id: 'active', title: 'Active' },
        { id: 'inactive', title: 'Inactive' }
      ],
      activatedFields: [
        'id',
        'name',
        'owner',
        'created.from',
        'created.to',
        'state'
      ],
      filters: [
        { type: 'eq', field: 'state', value: 'active' }
      ],
      prefix: ''
    }
  },
  computed: {
    ...mapGetters([
      'isClient',
      'appOptions'
    ]),
    filterBtnText () {
      return this.$t('Filter')
    }
  },
  mounted () {
    if (this.options && this.options.filters) {
      this.filters = [...this.options.filters, ...this.filters]
    }

    this.loadDefaultItems()

    if (this.appOptions.versionType !== 'simple') {
        this.prefix = '/customer-portals'
    }
  },
  methods: {
    ...mapMutations([
      'setSender',
    ]),
    refreshItems () {
      return this.onRequest({
        pagination: {
          forceReload: true
        }
      })
    },
    upsertItem (item) {
      let isExist = false

      this.items = this.items.map(x => {
        if (x.id === item.id) {
          isExist = true
          return item
        }

        return x
      })

      if (!isExist) {
        this.items.unshift(item)
      }

      return this.items
    },
    handleStateChange (item) {
      const invertedState = {
        active: 'inactive',
        inactive: 'active'
      }

      const state = invertedState[item.state]

      return this.$service.sender.save({ state }, item.id)
        .then(item => {
          this.upsertItem(item)
        })
    },
    handleFiltersSubmit (filter) {
      this.isOpen = false
      this.filters = filter
      return this.onRequest({ pagination: { page: 1 } })
    },
    toggleFilter () {
      this.isOpen = !this.isOpen
    },
    loadDefaultItems () {
      return this.onRequest({ pagination: { page: 1, rowsPerPage: 5 } })
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)
      query.filter = this.filters

      this.isLoading = true
      return this.$service.sender.getAll(query)
        .then(({ items, totalPages, page, totalItems }) => {
          this.pagination = {
            ...this.pagination,
            page,
            rowsNumber: totalItems
          }

          this.isLoading = false
          this.items = items

          return { items, totalPages, page, totalItems }
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    create () {
      this.$router.push(`${this.prefix}/sender/entity/`)
    },
    handleSearch (search) {
      return this.onRequest({ pagination: { search, page: 1 } })
    },
    onRowDblClick (row) {
      this.setSender(row)
      this.$router.push(`${this.prefix}/sender/entity/${row.id}`)
    },
    getColumns() {
      if (this.appOptions.versionType === 'simple') {
        return this.columns.filter(column => column.simple);
      }
      return this.columns;
    }
  }
}
</script>
